<!-- <h1 class="title">Events Monthly View</h1> -->
<app-breadcrums [data]="breadCrum"></app-breadcrums>
<div class="month-title" style="margin: 10px 0px;">
    <h1>{{ this.month }}, {{ this.year }}</h1>
</div>
<div class="calendar-header">
   
   
     <!-- <div class="col-md-3">
        <label>Team</label>
        <ng-select class="ng-select" [(ngModel)]="this.teamIds" [multiple]="true" name="teams" placeholder="Please Select Team" (ngModelChange)="search()">
            <ng-container *ngFor="let team of teamsList">
                <ng-option [value]="team.id" *ngIf="team.status === 'Active'">{{ team.title }}</ng-option>
            </ng-container>
        </ng-select>
    </div> -->
            <div class="direction-icons col-md-2">
                <button class="cg" (click)="previousMonth()"><i class="fas fa-chevron-left"></i></button>
                <button class="cg ms-2" (click)="nextMonth()"><i class="fas fa-chevron-right"></i></button>
            </div>    
            <div class="col-md-2 cg-input-group">
              <label>Mentors</label>
              <ng-select [(ngModel)]="mentorId" [multiple]="true" [clearable]="false" placeholder="Select Mentor(s)"
              (ngModelChange)="withId()">
                <!-- <ng-option [value]="!instructorIds">Select Instructor</ng-option> -->
                <!-- <ng-option value="">Select All</ng-option> -->
                <ng-option *ngFor="let item of mentorList" [value]="item.id">{{ item.name }}</ng-option>
              </ng-select>
            </div>
            <div class="col-md-2">
                <input readonly bsDatepicker
                    [bsConfig]="{ minMode: 'month', containerClass: 'theme-orange', dateInputFormat: 'MMMM', adaptivePosition: true, showWeekNumbers: false }"
                    class="cg" style="background-color: #fff" [(ngModel)]="fIlterMonth"
                 
                    (ngModelChange)="fIlterMonthYear()"
                     placement="left" placeholder="Select Month" />
            </div>
            <div class="col-md-2">
                <input readonly bsDatepicker
                    [bsConfig]="{ minMode: 'year', containerClass: 'theme-orange', dateInputFormat: 'YYYY', adaptivePosition: true, showWeekNumbers: false }"
                    class="cg" style="background-color: #fff" [(ngModel)]="fIlterYear"
                    (ngModelChange)="fIlterMonthYear()"
                     placement="left" placeholder="Select Year" />
            </div>
            
            <!-- <div class="col-md-2">
                <button [disabled]="waiting.save" [ngClass]="{ 'in-progress': waiting.save }" type="button" class="cg secondary"
                (click)="getCalendarView()" style="margin-top: 18px;">
                search
                <span></span>
                <span></span>
                <span></span>
            </button>
            </div> -->
    
    
        
   
    <div class="legends col-lg-6 col-md-4 col-sm-12">
            <div class="legend makeup">
              <span class="daba" [style.background-color]="scheduled"></span>
              <div>Scheduled</div>
            </div>
        
            <div class="legend free-slot">
              <span class="daba" [style.background-color]="inProgress"></span>
              <div>In Progress</div>
            </div>
        
            <div class="legend blocked-slot">
              <span class="daba" [style.background-color]="canceled"></span>
              <div>Canceled</div>
            </div>
            <div class="legend blocked-slot">
              <span class="daba" [style.background-color]="completed"></span>
              <div>Completed</div>
            </div>
          </div>
</div>
<div class="box"  style="margin-top: 10px; ">
    <div class="table-responsive">
        <table class="table" >
            <thead>
                <tr>
                    <th *ngFor="let d of days; let i = index" class="days-color">{{ d }}</th>
                </tr>
            </thead>
            <!-- <tbody *ngIf="dataStatus === 'fetching'" [appSkeletonTabelLoader]="loaderOptions"></tbody> -->
            <tbody>
                <tr *ngFor="let week of this.calendar; let i; index">
                    <td *ngFor="let weekDay of week.days">
                        <span [class.blur]="!checkDay(weekDay.date)">{{ weekDay.day }}</span>
                        <ng-container>
                            <div class="party" *ngFor = "let mentor of mentors">
                                
                                <ng-container *ngFor="let d of mentor.inspections; let i=index">

                                    <div *ngIf="checkEvent(d.date, weekDay.date)" class="color-block"
                                        [class.scheduled] = "d.status == 'scheduled'"
                                        [class.inProgress]= "d.status == 'inProgress'"
                                        [class.completed]= "d.status == 'completed'"
                                        [class.canceled]= "d.status == 'canceled'"
                                        style="cursor: pointer;"
                                        (click) = "openModal(parallel, d)">
                                        <p *ngIf="d.parallels.length < 100">{{d.parallels.length}}</p>
                                        <p  *ngIf="d.parallels.length > 99" style="font-size: 12px; margin-top: 2px;">90+</p>
                                        <span class="tooltips">
                                            <p> Mentor: {{mentor.firstName}} {{mentor.lastName}}</p><br/>
                                            <div class="d-flex flex-row justify-content-between">
                                              
                                                <!-- Complete Address: {{ d.house }},{{d.street}},{{d.city}}, {{d.state}},{{d.zipCode}} <br /> -->
                                                Complete Address: {{ d.address }}<br/>{{d.addressTwo}}<br/>{{d.city}},{{d.zipCode}} <br />
                                            </div>  
                                                
                                                Start Time: {{moment(d.startTime, 'HH:mm').format('hh:mm A')}}<br />
                                                <!-- End Time: {{moment(d.endTime, 'HH:mm').format('hh:mm A')}}<br /> -->
                                        </span>
                                    </div>

                                </ng-container>
                            </div>
                        </ng-container>
                    </td>
                </tr>
            </tbody>
            <!-- <tbody *ngIf="!datafound && dataStatus == 'done'">
                <tr>
                    <td colspan="4">
                        <app-no-data-found></app-no-data-found>
                    </td>
                </tr>
            </tbody> -->
        </table>
    </div>
</div>
<ng-template #parallel>
    <div class="modal-header" style="flex-wrap: wrap;">
        <p class="secondary w-100" style="font-weight: 600;">{{inspection.address}}</p>
        <p style="font-size: 12px;">{{inspection.date}} / {{ moment(inspection.startTime, 'HH:mm:ss').format('hh:mm A') }}</p>
    </div>
    <div class="container scrolling back-office-panel-cg">
        <div class="table-responsive" style="overflow-y: scroll; height: 300px;">
            <table class="table">
                <thead>
                    <tr>
                        <th class="text-left">Name</th>
                        <th class="text-left">Contact</th>
                        <!--<th class="text-left">Phone Number</th>-->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of inspection.parallels" class="h-tr">
                        <td class="text-left" style="height: 40px !important; border: none;">{{item.name}}</td>
                        <td class="text-left" style="height: 40px !important; border: none;">{{item.contactOne}}</td>
                        <!--<td class="text-left">{{item.studentcontacts[0]?.contactOne}}</td>-->
                    </tr>
                    
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer col-md-12 mb-12 mt-4">
        <button type="button" class="cg ml-3 secondary" (click)="modalRef.hide()">Cancel</button>
    </div>
</ng-template>
<!-- <ng-template #addEditTemplate>
    <div class="modal-header">
        <h1 class="title">{{ modalTitle }}</h1>
    </div>
    <div class="modal-body">
        <form [formGroup]="eventsForm" #f="ngForm">
            <div class="row">
                <div class="col-md-6">
                    <label>Title <span class="mendatory">*</span></label>
                    <input type="text" formControlName="title" class="cg form-control mt-0" placeholder="Enter Title" />
                    <div *ngIf="ui.checkErrors(g.title, f)" class="errors">
                        <p *ngIf="g.title.errors?.required">Title is required</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <label>Teams <span class="mendatory">*</span></label>
                    <div class="cg-input-group" style="width: 100%">
                         <ng-select class="ng-select" [(ngModel)]="this.teamIds" formControlName="teamIds" placeholder="Select Teams" [multiple]="true" [ngClass]="{ 'form-submitted': f.submitted }"> 
                        <ng-select class="ng-select" formControlName="teamIds" placeholder="Select Teams"
                            [multiple]="true" [ngClass]="{ 'form-submitted': f.submitted }" (change)="teamSelect()">
                            <ng-option [value]="0">All Teams </ng-option>
                            <ng-option *ngFor="let c of teamsList" [value]="c.id">{{ c.title }}</ng-option>
                        </ng-select>
                    </div>
                    <div *ngIf="ui.checkErrors(g.teamIds, f)" class="errors">
                        <p *ngIf="g.teamIds.errors?.required">Team is required</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <label>Start Date <span class="mendatory">*</span></label>
                    <input type="text" class="cg" formControlName="startDate" placeholder="Enter Start Date"
                        bsDatepicker
                        [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: this.cs.DATE_TIME_FORMAT.SYN_DATE, adaptivePosition: true, showWeekNumbers: false }"
                        readonly [ngClass]="{ 'form-submitted': f.submitted }" />
                    <div *ngIf="ui.checkErrors(g.startDate, f)" class="errors">
                        <p *ngIf="g.startDate.errors?.required">Start date is required</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <label>End Date <span class="mendatory">*</span></label>
                    <input type="text" class="cg" formControlName="endDate" placeholder="Enter End Date" bsDatepicker
                        [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: this.cs.DATE_TIME_FORMAT.SYN_DATE, adaptivePosition: true, showWeekNumbers: false }"
                        readonly [ngClass]="{ 'form-submitted': f.submitted }" />
                    <div *ngIf="ui.checkErrors(g.endDate, f)" class="errors">
                        <p *ngIf="g.endDate.errors?.required">End date is required</p>
                    </div>
                </div>
                <div class="col-md-6 mt-2">
                    <div class="cg input-group" style="position: relative">
                        <label class="mb-0">Start Time <span class="mendatory">*</span></label>
                        <timepicker id="timepicker" class="cg time-wrapper" formControlName="startTime"
                            (ngModelChange)="startTimeChange()" [ngClass]=" { 'form-submitted' : f.submitted }">
                        </timepicker>
                    </div>
                    <div *ngIf="ui.checkErrors(g.startTime, f)" class="errors">
                        <p *ngIf="g.startTime.errors?.required">Start Time is required</p>
                    </div>
                </div>
                <div class="col-md-6 mt-2">
                    <label class="mb-0">End Time <span class="mendatory">*</span></label>
                    <timepicker id="timepicker" class="cg time-wrapper" formControlName="endTime"
                        [ngClass]="{ 'form-submitted': f.submitted }"></timepicker>
                    <div *ngIf="ui.checkErrors(g.endTime, f)" class="errors">
                        <p *ngIf="g.endTime.errors?.required">End Time is required</p>
                    </div>
                </div>
                <div class="content-responsive">
                    <div class="col-md-12 content-height editor-height">
                        <label class="mb-">Content<span class="mendatory">*</span></label>
                        <quill-editor formControlName="content" [modules]="editorConfig"
                            [ngClass]="{ 'form-submitted': f.submitted }"></quill-editor>
                        <div *ngIf="ui.checkErrors(g.content, f)" class="errors">
                            <p *ngIf="g.content.errors?.required">Content is required</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btns">
                <div class="col-md-12 d-flex justify-content-end">
                    <button type="button" id="btns2" class="cg warning" (click)="cancelButton(f)">Cancel</button>
                    <button type="button" id="btns2" (click)="save(f)" class="cg primary ms-2"
                        [ngClass]="{ 'in-progress': waiting?.save }">
                        Save
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template> -->
