import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { apis } from 'src/environments/environment'
@Injectable()
export class DataService {
    private baseUrl = `${apis.baseUrl}`

    constructor(public http: HttpClient) {
        // Code Here
    }

    getInspections(params: any): Observable<any> {
        const url = `${this.baseUrl}/admin/inspections/calendar`
        return this.http.post<any>(url, params)
    }
    getMentor(): Observable<any> {
        const url = `${this.baseUrl}/admin/dropdown/mentors`
        return this.http.get<any>(url)
    }
    // getInstructorScheduleDaysTime(): Observable<any> {
    //     const url = `${this.baseUrl}/settings`
    //     return this.http.get<any>(url)
    // }

    // makeupClass(): Observable<any> {
    //     const url = `${this.baseUrl}/settings`
    //     return this.http.get<any>(url)
    // }

    // getInstructorsecondaryClasses(): Observable<any> {
    //     const url = `${this.baseUrlInstructor}/secondary-classes`
    //     return this.http.get<any>(url)
    // }

    // getInstructorBlockSlots(params: any = { }): Observable<any> {
    //     const url = `${this.baseUrlInstructor}/block-slots`
    //     return this.http.get<any>(url, { params })
    // }
}
